<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" type="primary" @click="addWeChatSys">新增</el-button>
    </div>
    <div class="tabItem_contentBox billing_table">
      <el-table id="weChatSys" :data="tableData" border style="width: 100%">
        <!-- 操作 -->
        <el-table-column label="操作" fixed width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateWeChatSys(scope.row)" title="修改"></i>
          </template>
        </el-table-column>
        <el-table-column prop="addressbook_corpsecret" label="通讯录管理secret" width="600">
        </el-table-column>
        <el-table-column prop="corpid" label="企业微信企业ID" min-width="120">
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增修改弹窗-->
    <el-dialog :title="(operateState == 0 ?'新增':'修改')" :before-close="cancleShowWeChatSys" :visible.sync="showWeChatSys"
      width="700px" append-to-body>
      <div class="financeDialog scollStyle">
        <!-- ref="weChatTable" :rules="weChatRules" -->
        <el-form size="mini" label-width="80px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="通讯录管理secret:" prop="name">
                <el-input v-model="addressbook_corpsecret"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="企业微信企业ID:" prop="id">
                <el-input v-model="corpid"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleShowWeChatSys">取 消</el-button>
        <el-button size="small" type="primary" @click="saveWeChatSys">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入方法
  import api from '@/network/system/basicsetup'

  export default {
    name: 'wechatSys',
    data() {
      return {

        addressbook_corpsecret: '', //通讯录管理secret
        corpid: '', //企业微信id
        weChatRules: '', //表单验证
        showWeChatSys: false, //默认新增弹窗关闭
        operateState: 0, //0新增 1修改
        addressbook_corpsecret: '', //通讯录管理secret
        corpid: '', //企业微信企业id
        tableData: [], //表格数据
        id: '', //修改用到id
      }
    },
    created() {
      // 初始化表单验证规则
      this.initRules();
    },
    mounted() {
      this.findWeiXinSecret(); //查询企业微信配置
    },
    methods: {
      //查询企业微信配置
      findWeiXinSecret() {
        this.loading = this.commonJsExtend.customLoading("#weChatSys", 3);
        api.findWeiXinSecret().then(res => {
          if (res.code == 200) {
            this.loading.close();//关闭loading
            console.log(res.data);
            this.tableData=[];//清空数组
            this.tableData.push(res.data);
          }
        })
      },
      //点击编辑按钮触发
      updateWeChatSys(data) {
        this.operateState = 1;
        this.addressbook_corpsecret = data.addressbook_corpsecret;
        this.corpid = data.corpid;
        this.showWeChatSys = true;
        this.id = data.id;
      },
      /* 初始话表单验证规则*/
      initRules() {
        /*   this.weChatRules = {
             name: [ //客户分类名称规则
               { //规则1
                 required: true, //是否必填
                 message: '请输入名称', //该规则错误信息提示
                 trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
               }
             ],
             id: [{
               required: true, //是否必填
               message: '请输入id', //该规则错误信息提示
               trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
             }]
           } */
      },
      //控制打开新增弹窗
      addWeChatSys() {
        this.operateState = 0;
        this.showWeChatSys = true;
      },
      //取消新增弹窗
      cancleShowWeChatSys() {
        this.showWeChatSys = false;
        /*    this.$refs['weChatTable'].resetFields(); */ //移除表单验证规则
      },
      //保存新增或者修改配置
      saveWeChatSys() {
        /*   this.$refs['weChatTable'].validate(valid => {
              if (valid) { */

        if (this.operateState == 0) { //新增
          if (this.tableData != null || this.tableData != '') {
            this.$message({
              type: 'error',
              message: '数据已经存在，请修改',
              duration: this.elDuration
            })
            this.showWeChatSys = false; //关闭弹窗
            return
          }
          let data = {
            addressbook_corpsecret: this.addressbook_corpsecret,
            corpid: this.corpid
          }
           this.loading = this.commonJsExtend.customLoading("#weChatSys",0);
          api.addWeiXinSecret(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.$message({
                type: 'success',
                message: '新增成功!',
                duration: this.elDuration
              })
              this.showWeChatSys = false; //关闭弹窗
              this.findWeiXinSecret(); //获取新数据
            } else {
              this.$message({
                type: 'error',
                message: '新增失败!',
                duration: this.elDuration
              })
            }
          })
        } else if (this.operateState == 1) { //编辑
          let data = {
            addressbook_corpsecret: this.addressbook_corpsecret,
            id: this.id,
            corpid: this.corpid
          }
          this.loading = this.commonJsExtend.customLoading("#weChatSys",1);
          //编辑方法
          api.updateWeiXinSecret(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.findWeiXinSecret();
              this.showWeChatSys = false; //修改弹窗
            } else {
              this.$message({
                type: 'error',
                message: '修改失败!',
                duration: this.elDuration
              })
            }

          })
        }

        /*   }
         }) */


      }
    }
  }
</script>

<style>
</style>
