// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：系统.基本配置相关请求
// 开始时间：2021-05-07
// 开发人员：姚雨宏
// 最后修改：2021-05-07
// 备注说明：如需修改请联系开发人员

import {
  request
} from "../request.js";
import qs from 'qs';

export default{
  addWeiXinSecret(data){//添加企业微信配置
    return request({
      method:'POST',
      url:'/weixin/addWeiXinSecret',
      data
    })
  },
  updateWeiXinSecret(data){//修改企业微信配置
    return request({
      method:'POST',
      url:'/weixin/updateWeiXinSecret',
      data
    })
  },
  findWeiXinSecret(data){//查询企业微信配置
    return request({
      method:'POST',
      url:'/weixin/findWeiXinSecret',
      data
    })
  },
  findAllApiConfig(data){//查询api配置
    return request({
      method:'POST',
      url:'/system/apiConfig/findAllApiConfig',
      data
    })
  },
  updateApiConfig(data){//修改api配置
    return request({
      method:'POST',
      url:'/system/apiConfig/updateApiConfig',
      data
    })
  },
  deleteApiConfig(data){//删除api配置
    return request({
      method:'POST',
      url:'/system/apiConfig/deleteApiConfig',
      data
    })
  },
  addApiConfig(data){//添加api配置
    return request({
      method:'POST',
      url:'/system/apiConfig/addApiConfig',
      data
    })
  },
}
